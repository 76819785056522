<template>
  <div style="height: 100%;">
    <!-- :style="checkStatus ? 'height: 270px;' : 'height: 350px;'" -->
    <v-card-text
      style="background-color: #e5ddd5;  overflow-y: auto; position:relative;"
      :style="checkStatus ? 'height:calc(100% - 80px);' : ''"
    >
      <div class="notas-bg"></div>
      <div v-if="notas && notas.length" class="notas-container px-4 py-4">
        <div
          class="nota-container "
          v-for="nota in notas"
          :key="nota.id"
          :class="{
            'align-start': !isSameUser(nota.user_id),
            'align-end': isSameUser(nota.user_id),
          }"
        >
          <div
            class="nota px-3 py-1 mt-3"
            :class="{
              usermessage: isSameUser(nota.user_id),
              lightgray: !isSameUser(nota.user_id),
            }"
          >
            <div>
              <v-avatar size="40" class="mr-3">
                <v-img :src="buscaAvatar(nota.user_id)"> </v-img>
              </v-avatar>
            </div>
            <div>
              <div class="nota-header caption toolbar--text font-weight-bold">
                {{ buscaUsuario(nota.user_id) }}
                <div class="mx-2" v-if="$vuetify.breakpoint.mdAndUp">-</div>
                <div class="nota-subheader">
                  <div
                    style="font-size: 10px; line-height: 1.1;"
                    v-if="nota.created_at"
                  >
                    {{ nota.created_at | moment("from", "now") }}
                  </div>
                  <v-btn
                    color="primary"
                    class="ml-2"
                    icon
                    small
                    @click="openDialogImages(nota.id)"
                  >
                    <v-icon size="23">camera_alt</v-icon>
                  </v-btn>
                  <v-avatar
                    class="ml-1 caption"
                    color="primary"
                    size="17"
                    max-width="20px"
                  >
                    <span class="white--text" style="font-size: 11px">
                      {{ nota.imgs.length ? nota.imgs.length : "0" }}
                    </span>
                  </v-avatar>
                </div>
              </div>
              <div class="nota-message">
                <div class="caption">{{ nota.nota }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Carregando v-else-if="!notas" />
    </v-card-text>
    <!-- Inserir uma nota -->
    <template v-if="checkStatus">
      <v-divider></v-divider>
      <v-card
        color="lightgray"
        flat
        tile
        height="80px"
        class="px-4 py-4 d-flex align-center justify-space-between"
        :loading="loadingNovaNota"
        :disabled="loadingNovaNota"
      >
        <v-card outlined width="100%">
          <v-textarea
            v-model="nota"
            placeholder="Escreva uma nota..."
            :rows="1"
            auto-grow
            class="pa-0 ma-0"
            :row-height="24"
            solo
            flat
            hide-details
            background-color="white"
          ></v-textarea>
        </v-card>
        <v-btn
          v-if="notaTextFocus"
          @click="salvarNota"
          :disabled="!notaValid"
          class="ml-3"
          color="anchors"
          icon
          large
        >
          <v-icon large>send</v-icon>
        </v-btn>
      </v-card>
    </template>

    <SelectImageModal
      v-if="notaId && dialogImages"
      :dialogImages.sync="dialogImages"
      origem="eventos_notas"
      :origem_id="notaId"
    >
    </SelectImageModal>
  </div>
</template>

<script>
import {
  getEventosNotasPorEvento,
  postEventosNota,
  uploadImage,
} from "@/services/api/eventos-notas.api.js";
import { mapState, mapActions } from "vuex";

export default {
  name: "EventoNotas",
  props: ["eventoId"],
  components: {
    SelectImageModal: () => import("@/components/SelectImageModal"),
  },
  data() {
    return {
      loadingNovaNota: false,
      notaTextFocus: false,
      notas: [],
      nota: "",
      notaId: null,
      selectedFile: null,
      uploadingImage: false,
      dialogImages: false,
      imagePath: null,
      apiUrl: null,
      evento: {},
    };
  },
  computed: {
    ...mapState("Evento", {
      original: (state) => state.evento,
    }),
    ...mapState("Usuario", {
      isAdmin: (state) => state.usuario.is_admin,
      user_id: (state) => state.usuario.id,
      usuarios: (state) => state.usuarios,
    }),
    notaValid() {
      let valid = false;
      if (this.nota.length > 4) {
        valid = true;
      }
      return valid;
    },
    evento_id() {
      return this.$route.params.eventoId;
    },
    checkStatus() {
      if (
        this.evento.status != 3 &&
        this.evento.status != 4 &&
        this.evento.status != -1
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    nota() {
      if (this.nota.length > 4) {
        this.notaTextFocus = true;
      } else {
        this.notaTextFocus = false;
      }
    },
  },
  methods: {
    isSameUser(UserNota) {
      return UserNota === this.user_id ? true : false;
    },
    buscaUsuario(id) {
      let user = this.usuarios.find((item) => item.id === id);
      if (user) return user.name.charAt(0).toUpperCase() + user.name.slice(1);
    },
    buscaAvatar(id) {
      let user = this.usuarios.find((item) => item.id === id);
      if (user && user.avatar) {
        return this.apiUrl + user.avatar;
      } else {
        return require("@/assets/avatar_placeholder.png");
      }
    },
    getEventosNotas() {
      getEventosNotasPorEvento(`?evento_id=${this.evento_id}`)
        .then((response) => {
          this.notas = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    salvarNota() {
      this.loadingNovaNota = true;
      this.$Progress.start();
      let notaObj = {};
      notaObj.evento_id = this.evento_id;
      notaObj.user_id = this.user_id;
      notaObj.nota = this.nota;
      postEventosNota(notaObj)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Nota criada com sucesso!");
            this.getEventosNotas();
            this.nota = "";
            this.$Progress.finish();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.loadingNovaNota = false;
        });
    },
    openDialogImages(id) {
      this.notaId = id;
      this.dialogImages = true;
    },
  },
  mounted() {
    if (process.env.VUE_APP_API_URL) {
      this.apiUrl = process.env.VUE_APP_API_URL;
    }
    this.getEventosNotas();
    this.evento = this.lodash.cloneDeep(this.original);
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

.notas-bg {
  background-image: url("../../../assets/bg-chat-tile.png");
  background-repeat: repeat;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.06;
}

.notas-container {
  display: flex;
  position: relative;
  flex-direction: column;
  z-index: 3;
  opacity: 1;

  .nota-container {
    display: flex;

    &.align-end {
      justify-content: flex-end;
    }

    &.align-start {
      justify-content: flex-start;
    }

    .nota {
      max-width: 65%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-radius: 6px;

      @media #{map-get($display-breakpoints, 'md-and-down')} {
        align-items: flex-start;
        max-width: 90% !important;
      }

      .nota-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media #{map-get($display-breakpoints, 'md-and-down')} {
          flex-direction: column;
          align-items: flex-start;

          .nota-subheader {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
</style>
